import { ifDark, ifLight, styledColor } from 'meditopia-ui/utils'
import styled, { css } from 'styled-components'
import { ifNotProp } from 'styled-tools'
import Button from '../Button'
import { fontVariants } from '../Text/variants'

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow-x: auto;
`

export const TabButton = styled(Button)<{ isActive: boolean }>`
  ${fontVariants['caption-small']};
  border: 1px solid ${styledColor('secondary.main')};
  min-width: fit-content;
  ${ifNotProp(
    'isActive',
    css`
      ${ifDark(css`
        color: ${styledColor('white.100')};
        background-color: transparent;
      `)}

      ${ifLight(css`
        color: ${styledColor('gray.400')};
        background-color: ${styledColor('white.100')};
        border: 1px solid ${styledColor('gray.100')};
      `)}
    `
  )}

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`

TabButton.defaultProps = {
  colorScheme: 'secondary',
  size: 'tiny'
}
