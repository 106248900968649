import * as React from "react";
import { SVGProps, memo } from "react";

const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm4.767 15.785a.917.917 0 0 1-1.302.055l-4.583-4.162a.915.915 0 0 1-.257-.678V6.958a.917.917 0 0 1 1.833 0v4.639l4.245 3.896a.917.917 0 0 1 .064 1.292Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgTime);
export default Memo;
