import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { v4 } from 'uuid'
import { ToastContextState, IToast, IToastWithId } from './types'
import { ToastsContainer, ToastsWrapper } from './styled'
import Toast from './Toast'
import { ANIMATION_DURATION, DEFAULT_DURATION } from './config'

const ToastContext = createContext<ToastContextState>({} as ToastContextState)

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<IToastWithId[]>([])

  const addToast = ({
    duration = DEFAULT_DURATION,
    status = 'info',
    message = '',
    title
  }: IToast): IToastWithId => {
    const toast: IToastWithId = {
      title,
      message,
      duration,
      status,
      id: v4(),
      active: false
    }
    setToasts((prev) => [...prev, toast])
    setTimeout(() => activate(toast.id), 10)
    setTimeout(() => deactivate(toast.id), toast.duration)
    return toast
  }

  const activate = (id: string) => {
    setToasts((prev) => {
      const toast = prev.find((t) => t.id === id)
      if (toast) toast.active = true
      return [...prev]
    })
  }

  const deactivate = (id: string) => {
    setToasts((prev) => {
      const toast = prev.find((t) => t.id === id)
      if (toast) toast.active = false
      return [...prev]
    })

    setTimeout(() => remove(id), ANIMATION_DURATION)
  }

  const remove = (id: string) => {
    setToasts((prev) => prev.filter((t) => t.id !== id))
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast: deactivate }}>
      {children}
      <ToastsWrapper>
        <ToastsContainer>
          {toasts.map((toast) => (
            <Toast key={toast.id} {...toast} />
          ))}
        </ToastsContainer>
      </ToastsWrapper>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
