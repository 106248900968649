import React, { FC, ReactNode } from 'react'
import { TagStyled } from './styled'
import { TagProps } from './types'

interface IconTagProps
  extends Omit<TagProps, 'iconLeft' | 'iconRight' | 'textVariant' | 'text'> {
  icon: ReactNode
}

const IconTag: FC<IconTagProps> = ({
  icon,
  width = '6',
  height = '6',
  px = 0,
  py = 0,
  display = 'flex',
  alignItems = 'center',
  justifyContent = 'center',
  lineHeight = 0,
  fontSize = '0.75rem',
  ...props
}) => {
  return (
    // @ts-ignore
    <TagStyled
      width={width}
      height={height}
      px={px}
      py={py}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      lineHeight={lineHeight}
      fontSize={fontSize}
      {...props}>
      {icon}
    </TagStyled>
  )
}

export default IconTag
