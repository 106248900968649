import * as React from "react";
import { SVGProps, memo } from "react";

const SvgChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.498 5.52c.67.695.67 1.82 0 2.515l-9.922 10.288a2.173 2.173 0 0 1-3.152 0l-3.922-4.067a1.824 1.824 0 0 1 0-2.514 1.671 1.671 0 0 1 2.424 0L9 14.93l9.073-9.408a1.671 1.671 0 0 1 2.425 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgChecked);
export default Memo;
