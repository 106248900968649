import { componentFactory } from 'meditopia-ui/utils'
import styled from 'styled-components'
import {
  layout,
  color,
  space,
  boxShadow,
  flexbox,
  border,
  typography,
  position,
  system
} from 'styled-system'
import type { BoxProps } from './types'

export const BoxBase = styled.div<BoxProps>(
  layout,
  color,
  space,
  boxShadow,
  flexbox,
  border,
  typography,
  position,
  system({
    transform: true
  }),
  system({
    pointerEvents: true
  }),
  system({
    transition: true
  }),
  ({ spaceX }) =>
    spaceX && {
      marginLeft: `calc(${spaceX} / -2)`,
      marginRight: `calc(${spaceX} / -2)`,
      '&>*': {
        marginLeft: `calc(${spaceX} / 2)`,
        marginRight: `calc(${spaceX} / 2)`
      }
    },
  ({ spaceY }) =>
    spaceY && {
      marginTop: `calc(${spaceY} / -2)`,
      marginBottom: `calc(${spaceY} / -2)`,
      '&>*': {
        marginTop: `calc(${spaceY} / 2)`,
        marginBottom: `calc(${spaceY} / 2)`
      }
    }
)

export const Box = componentFactory(BoxBase)
