import { ParsedUrlQuery } from 'querystring'

export * from './blurDataURL'
export * from './favoritesDataMap'
export * from './dates'
export * from './coaching'

// date exmp. 2/23
export const addZeroToBeginingsOfDate = (date: string) => {
  return (date.split('/')[0].trim().length as number) < 2 ? `0${date}` : date
}

export const timezone = () => {
  const gmtValue = (new Date().getTimezoneOffset() / -60)
  const symbolPM = gmtValue < 0 ? '-' : '+'

  return `GMT${symbolPM}${(Math.abs(gmtValue))}`
}

export const calcDuration = (d: number) => {
  if (!d) return
  const minutes = Math.floor(d / 60)
  return minutes === 0 ? 1 : minutes
}

export const addImageUrlXs = (url: string) => {
  if (!url.includes('/series/')) return url

  const splitedUrl = url.split('/')
  splitedUrl[splitedUrl.length - 1] = 'xs/' + splitedUrl[splitedUrl.length - 1]
  return splitedUrl.join('/')
}

export const getDayPhase = () => {
  const today = new Date()
  const curHr = today.getHours()

  if (curHr < 12) {
    return 'Morning'
  } else if (curHr < 18) {
    return 'Afternoon'
  } else {
    return 'Evening'
  }
}

export const getQueryPersistedHref = ({
  href,
  keys,
  query
}: {
  href: string
  keys: string[]
  query: ParsedUrlQuery
}) => {
  if (!keys.some((key) => query[key])) {
    return href
  }

  const indexOfQuestionMark = href.indexOf('?')

  if (indexOfQuestionMark !== -1) {
    return `${href}${indexOfQuestionMark === href.length - 1 ? '' : '&'}${keys
      .filter((key) => !!query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`
  } else {
    return `${href}?${keys
      .filter((key) => !!query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')}`
  }
}
