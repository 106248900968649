import axios, { AxiosRequestHeaders } from 'axios'

const axiosInstance = function (baseURL: string, headers: AxiosRequestHeaders) {
  return axios.create({
    baseURL,
    headers,
    validateStatus: (status: number) => {
      return status <= 500
    }
  })
}

export default axiosInstance
