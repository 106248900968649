import { styledColor } from 'meditopia-ui/utils'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import Box from '../Box'

export const PlaceholderStyled = styled(Box)<{ isUp: boolean }>`
  display: block;
  color: ${styledColor('white.64', 'gray.500')};
  position: absolute;
  left: 1.5rem;
  font-size: 1rem;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.2s ease-in;
  font-family: sans-serif;

  @media (max-width: ${theme('breakpoints.sm')}) {
    font-size: 0.875rem;
    left: 1rem;
  }

  ${ifProp(
    'isUp',
    css`
      left: 1.6rem;
      top: 0.75rem;
      transform: none;
      font-size: 0.75rem;
      color: ${styledColor('white.40', 'gray.500')};

      @media (max-width: ${theme('breakpoints.sm')}) {
        top: 0.5rem;
        left: 1.1rem;
        font-size: 0.75rem;
      }
    `
  )}
`

export const InputStyled = styled.input`
  color: ${styledColor('white.64', 'gray.800')};
  width: calc(100% - 3rem);
  position: absolute;
  bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background: transparent;
  outline: none;
  border: none;

  @media (max-width: ${theme('breakpoints.sm')}) {
    width: calc(100% - 2rem);
    font-size: 0.875rem;
    bottom: 0.3rem;
  }
`

export const InputWrapperStyled = styled.label<{
  isFocused: boolean
  error: boolean
}>`
  display: block;
  height: 4rem;
  border-radius: 1rem;
  position: relative;
  padding: 0 1.5rem;
  transition: 0.2s ease-in;
  border: 2px solid ${styledColor('white.16', 'gray.100')};

  @media (max-width: ${theme('breakpoints.sm')}) {
    height: 3rem;
    border-radius: 0.5rem;
    padding: 0 1rem;
  }

  ${ifProp(
    'isFocused',
    css`
      border: 2px solid ${styledColor('secondary.main')};
      outline: 3px solid ${styledColor('secondary.main')}56;
    `
  )}

  ${ifProp(
    'error',
    css`
      border: 2px solid ${styledColor('warning')};
      outline: 3px solid ${styledColor('warning')}56;
    `
  )}
`

export const ErrorContainerStyled = styled(Box)<{ isActive: boolean }>`
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  opacity: 0;

  ${ifProp(
    'isActive',
    css`
      margin-top: ${theme('space.2')};
      max-height: 5rem;
      opacity: 1;
    `
  )}
`

ErrorContainerStyled.defaultProps = {
  ml: ['4', '6']
}
