import type { State, IGlobalContext, IAppProvider, Action } from 'types/context'

import React, { createContext, useContext, useReducer } from 'react'
import reducer from './reducer'

export let initialState: State = {
  loading: false,
  loading2: false
}

if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
  const storageData = localStorage.getItem('state')

  if (storageData) {
    initialState = {
      ...initialState,
      ...JSON.parse(storageData)
    }
  }
}

export const initialReducerState: IGlobalContext = {
  dispatch: () => {},
  state: initialState
}

const GlobalContext = createContext<IGlobalContext>(initialReducerState)

export const AppProvider = ({ children }: IAppProvider) => {
  const [state, dispatch]: [State, React.Dispatch<Action>] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)
