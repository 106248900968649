import { PageLoaderWrapper, Spinner } from './stlyed'

const PageLoading = () => {
  return (
    <PageLoaderWrapper>
      <Spinner />
    </PageLoaderWrapper>
  )
}

export default PageLoading
