import React from 'react'
import { AlertContainerStyled, AlertStyled } from './styled'
import type { AlertProps } from './types'

const Alert: React.FC<AlertProps> = ({ error }) => {
  return (
    <AlertContainerStyled isActive={Boolean(error)}>
      <AlertStyled>{error}</AlertStyled>
    </AlertContainerStyled>
  )
}

export default Alert
