import colors from './colors'
import breakpoints from './breakpoints'
import space from './space'
import sizes from './sizes'
import radius from './radius'

export type ThemeMode = 'light' | 'dark'

export interface Theme {
  mode: ThemeMode
  colors: typeof colors
  breakpoints: typeof breakpoints
  space: typeof space
  sizes: typeof sizes
  radii: typeof radius
}

const theme: Theme = {
  mode: 'light',
  colors,
  breakpoints,
  space,
  sizes,
  radii: radius
}

export default theme
