import { useSession } from 'next-auth/react'
import Image from 'next/image'
import styled from 'styled-components'
import { styledColor } from 'meditopia-ui/utils'

export const AvatarStyled = styled.div`
  border-radius: 100%;
  overflow: hidden;
  line-height: 0;
  border: 2px solid ${styledColor('white.16', 'gray.0')};
  box-sizing: unset;
`

interface IAvatar {
  width: string
  height: string
  className?: string
}

export default function Avatar ({ width, height, className }: IAvatar) {
  const { data: session } = useSession()

  return (
    <AvatarStyled className={className}>
      <Image
        width={width}
        height={height}
        src={session?.user.picturePath || '/default-avatar.jpeg'}
        alt='User avatar'
      />
    </AvatarStyled>
  )
}
