import React, { FC } from 'react'
import { PropsWithLight } from 'types/types'
import Box from '../Box'
import { ButtonThemed, IconContainerStyled, Spinner } from './styled'
import { ButtonProps } from './types'

const Button: FC<PropsWithLight<ButtonProps>> = ({
  children,
  size = 'normal',
  colorScheme = 'primary',
  variant = 'contained',
  iconLeft,
  iconRight,
  loading = false,
  ...props
}) => {
  return (
    <ButtonThemed
      {...props}
      size={size}
      colorScheme={colorScheme}
      variant={variant}>
      {iconLeft && (
        <IconContainerStyled
          opacity={Number(!loading)}
          placement="left"
          size={size}>
          {iconLeft}
        </IconContainerStyled>
      )}

      <Box opacity={Number(!loading)}>{children}</Box>

      {iconRight && (
        <IconContainerStyled
          opacity={Number(!loading)}
          placement="right"
          size={size}>
          {iconRight}
        </IconContainerStyled>
      )}
      {loading && <Spinner />}
    </ButtonThemed>
  )
}

export default Button
