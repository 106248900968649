import type {
  ISignInPayload,
  ISignInServiceResponse,
  ISignUpPayload,
  IUserServiceResponse
} from 'types/next-auth'

import axiosInstance from './base'
import { v4 } from 'uuid'
import qs from 'qs'
import { timezone } from 'utils'

const $axios = axiosInstance(process.env.NEXT_PUBLIC_API_ROOT as string, {
  'Content-Type': 'application/json',
  Authorization: `Basic ${process.env.NEXT_PUBLIC_NEXTAUTH_SECRET}`
})

export const signInService = async ({
  data,
  locale
}: {
  data: ISignInPayload
  locale: string
}) => {
  const userData = await $axios.post<ISignInServiceResponse>(
    '/v4/login',
    { ...data },
    {
      headers: {
        lang: locale
      }
    }
  )

  return userData.data
}

export const signUpService = async function ({
  data,
  locale
}: {
  data: ISignUpPayload
  locale: string
}) {
  const userData = await $axios.post<ISignInServiceResponse>(
    '/v4/register',
    { ...data, udID: v4() },
    {
      headers: {
        lang: locale
      }
    }
  )

  return userData.data
}

export const refreshAccessTokenService = async ({
  data,
  locale
}: {
  data: { refreshToken: string }
  locale: string
}) => {
  const tokens = await $axios.post(
    '/v4/token/refresh',
    { ...data },
    {
      headers: {
        lang: locale
      }
    }
  )

  return tokens.data
}

export const getUser = async function ({
  authToken,
  locale
}: {
  authToken: string
  locale: string
}) {
  const userData = await $axios.get<IUserServiceResponse>('/v4/user', {
    headers: {
      Authorization: `Bearer ${authToken}`,
      lang: locale
    }
  })

  return userData.data
}

export const profileUpdate = async ({
  authToken,
  locale,
  data
}: {
  authToken: string
  locale: string
  data: any
}) => {
  const response = await $axios.post<{ error: boolean }>(
    '/v3/profileedit',
    qs.stringify(data),
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        lang: locale,
        timezone: timezone(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }
  )

  return response.data
}

export const sendForgotPasswordMail = async ({
  locale,
  data
}: {
  locale: string
  data: any
}) => {
  const response = await $axios.post(
    '/v3/forgetpassword',
    qs.stringify({ ...data, lang: locale }),
    {
      headers: {
        Authorization: 'Basic bWVkaXRhc3lvbjoxMDk4',
        lang: locale,
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }
  )

  return response.data
}

export const connectSlack = async ({
  authToken,
  locale,
  data
}: {
  authToken: string
  locale: string
  data: { slackUserId: string; slackTeamId: string }
}) => {
  const response = await $axios.post<{
    success: boolean
    errors: { code: string; message: string }[]
  }>(
    '/v4/slack-connect',
    { slackID: data.slackUserId, slackTeamID: data.slackTeamId },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        lang: locale
      }
    }
  )

  return response.data
}
