export const fontVariants = {
  'display-xxlarge': {
    fontSize: '52px',
    lineHeight: '62px',
    fontWeight: '800'
  },
  'display-xlarge': {
    fontSize: '48px',
    lineHeight: '57px',
    fontWeight: '700'
  },
  'display-large': {
    fontSize: '40px',
    lineHeight: '48px',
    fontWeight: '700'
  },
  'display-medium-large': {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700'
  },
  'display-medium': {
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '500'
  },
  'display-small': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '500'
  },
  'heading-large': {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  'heading-medium': {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '400'
  },
  'heading-small': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '700'
  },
  'heading-xsmall': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '700'
  },
  'body-medium': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  'body-regular': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  'body-small-medium': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '400'
  },
  'body-small-regular': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '400'
  },
  'body-tiny-medium': {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '400'
  },
  'body-tiny-regular': {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '400'
  },
  'body-xsmall-medium': {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: '400'
  },
  'body-xsmall-regular': {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: '400'
  },
  caption: {
    fontSize: '12px',
    lineHeight: '14.4px',
    fontWeight: '400',
    letterSpacing: '0.1em'
  },
  'caption-small': {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: '700',
    letterSpacing: '0.04em'
  },
  tiny: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: '400'
  }
} as const
