import * as React from "react";
import { SVGProps, memo } from "react";

const SvgApple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.323 22.114c-1.222 1.158-2.57.978-3.855.432-1.366-.557-2.615-.593-4.058 0-1.797.757-2.75.537-3.833-.432-6.11-6.143-5.208-15.5 1.736-15.852 1.684.088 2.863.91 3.855.978 1.475-.293 2.886-1.132 4.464-1.022 1.896.15 3.315.88 4.261 2.195-3.9 2.29-2.976 7.31.607 8.719-.717 1.838-1.637 3.654-3.179 4.997l.002-.015Zm-6.29-15.918c-.182-2.73 2.083-4.976 4.69-5.196.358 3.148-2.931 5.504-4.69 5.196Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgApple);
export default Memo;
