import React from 'react'
import { combineProps } from './componentFactory'

const componentWithDefaultProps = <ComponentType extends React.FC = React.FC>(
  Component: ComponentType,
  props: Parameters<ComponentType>['0']
) => {
  const NewComponent = Component
  NewComponent.defaultProps = {
    ...combineProps(Component.defaultProps || {}, props)
  }
  return NewComponent
}

export default componentWithDefaultProps
