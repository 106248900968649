import { styledColor, ifLight } from 'meditopia-ui/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import Box from '../Box'

export const BackdropStyled = styled(Box)<{
  transparent: boolean
  isActive: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${styledColor('black.64')};
  pointer-events: all;
  animation: ${ifProp('isActive', 'fadein', 'fadeout')} 0.4s ease-in-out
    forwards;

  ${ifLight('backdrop-filter: blur(4px)')};
  ${ifProp('transparent', 'background-color: transparent')};

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`
