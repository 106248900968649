import { system } from 'styled-system'

export interface BackdropFilterProps {
  backdropFilter?: string
}

export const backdropFilterSystem = system({
  backdropFilter: {
    properties: ['backdropFilter', 'WebkitBackdropFilter']
  }
})
