import React from 'react'
import { useTheme } from 'styled-components'

export const combineProps = <Type extends Record<string, any> = {}>(
  first: Type,
  second: Type
) => {
  const result = { ...first }

  for (const key in second) {
    if (Object.hasOwnProperty.call(second, key)) {
      const secondValue = second[key]

      if (
        !Object.hasOwnProperty.call(first, key) ||
        typeof secondValue !== 'object'
      ) {
        result[key] = secondValue
        continue
      }

      const firstValue = first[key]
      result[key] = combineProps(firstValue, secondValue)
    }
  }

  return result as Type
}

export const componentFactory = <CT extends React.ComponentType>(
  Component: CT
) => {
  type Props = CT extends React.ComponentType<infer P>
    ? Omit<P, '_light'>
    : never
  type PropsWithLight = Props & { _light?: Props }

  const GeneratedComponent: React.FC<PropsWithLight> = (props) => {
    let themeSpesificProps: any = {}
    const { mode } = useTheme()
    if (mode === 'light') themeSpesificProps = props._light
    return (
      <Component {...combineProps(props || {}, themeSpesificProps || {})} />
    )
  }
  return GeneratedComponent
}

export default componentFactory
