import { styledColor } from 'meditopia-ui/utils'
import styled from 'styled-components'

export const RangeWrapperStyled = styled.div`
  position: relative;
  line-height: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 0.5rem;
    background-color: ${styledColor('gray.0')};
    z-index: 2;
  }

  &::after {
    left: unset;
    right: 0;
  }
`

export const RangeStyled = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: ${styledColor('gray.500')};
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  position: relative;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background: ${styledColor('white.100')};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 99rem;
    background: ${styledColor('white.100')};
    border: none;
    cursor: pointer;
  }
`
