import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'
import { styledColor } from 'meditopia-ui/utils'
import { IToast } from './types'
import { ANIMATION_DURATION } from './config'

export const ToastsWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const ToastsContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  overflow-y: auto;
`

export const ToastIcon = styled.div`
  border-radius: 99rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
  margin-right: 0.5rem;
  color: ${styledColor('white.100')};
`

export const ToastStyled = styled.div<{
  status: IToast['status']
  active: boolean
}>`
  pointer-events: all;
  width: 100%;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  opacity: 0;
  transform: translateY(100%);
  transition: all ${ANIMATION_DURATION}ms ease-in-out;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  ${ifProp(
    'active',
    css`
      opacity: 1;
      transform: translateY(0);
    `
  )}

  ${switchProp('status', {
    success: css`
      background-color: ${styledColor('success')};
      /* ${ToastIcon} {
        color: ${styledColor('success')};
      } */
    `,
    info: css`
      background-color: ${styledColor('theLotusBlue')};
      /* ${ToastIcon} {
        color: ${styledColor('theLotusBlue')};
      } */
    `,
    warning: css`
      background-color: ${styledColor('warning')};
      /* ${ToastIcon} {
        color: ${styledColor('warning')};
      } */
    `,
    error: css`
      background-color: ${styledColor('warning')};
      /* ${ToastIcon} {
        color: ${styledColor('warning')};
      } */
    `
  })}
`
