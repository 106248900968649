import { componentFactory, styledColor, ifLight } from 'meditopia-ui/utils'
import styled, { css } from 'styled-components'
import { color } from 'styled-system'
import { ifProp } from 'styled-tools'
import Box from '../Box'
import type { CardProps } from './types'

export const CardBase = styled(Box)<CardProps>`
  background-color: ${styledColor('white.08', 'white.100')};

  ${ifProp(
    { level: '2' },
    css`
      background-color: ${styledColor('white.08', 'gray.0')};
    `,
    css`
      ${ifLight(css`
        box-shadow: 0 4px 8px ${styledColor('black.08')};
        border: 1px solid ${styledColor('gray.0')};
      `)}
    `
  )}
  ${color}
`

CardBase.defaultProps = {
  level: '1',
  borderRadius: '2xl'
}

export const Card = componentFactory(CardBase)
