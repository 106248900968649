import styled from 'styled-components'
import Box from '../Box'

export const TagStyled = styled(Box)`
  backdrop-filter: blur(2px);
`

TagStyled.defaultProps = {
  backgroundColor: 'black.24',
  color: 'white.100',
  px: '8px',
  py: '6px',
  borderRadius: 99,
  width: 'fit-content'
}
