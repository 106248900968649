import { useCallback, useEffect } from 'react'
import axios from 'axios'
import { getSession, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useAuthStore } from 'store/auth-store'
import { connectSlack } from 'services/auth'

const DISABLED_PATHS = ['/signOut', '/slack-connected']

const RedirectOnSuccess = () => {
  const { status } = useSession()
  const { isAuthenticated, setAuthenticated } = useAuthStore()
  const { query, locale, pathname, push } = useRouter()

  useEffect(() => {
    setAuthenticated(status === 'authenticated')
  }, [status, setAuthenticated])

  const checker = useCallback(async () => {
    console.log({ isAuthenticated })

    if (DISABLED_PATHS.includes(pathname) || !isAuthenticated) {
      if (query?.slackUserId) {
        // localStorage.setItem('LAST_SOCIAL_LOGIN', JSON.stringify({ query }))
        console.log('LAST_SOCIAL_LOGIN setted: ', { query })
      }
      return
    }

    const lastSocialLoginSuccessRaw = localStorage.getItem('LAST_SOCIAL_LOGIN')
    const lastSocialLoginSuccess = lastSocialLoginSuccessRaw
      ? (JSON.parse(lastSocialLoginSuccessRaw) as {
          query: {
            slackUserId?: string
            slackTeamId?: string
            callbackUrl?: string
          }
        })
      : null
    localStorage.removeItem('LAST_SOCIAL_LOGIN')

    const slackUserId =
      lastSocialLoginSuccess?.query?.slackUserId || query.slackUserId
    const slackTeamId =
      lastSocialLoginSuccess?.query?.slackTeamId || query.slackTeamId
    const callbackUrl =
      lastSocialLoginSuccess?.query?.callbackUrl || query.callbackUrl

    let redirectUrl = `${
      process.env.NEXT_PUBLIC_WEBAPP_URL || 'https://webapp.meditopia.com'
    }/${locale}/`

    if (slackUserId && slackTeamId) {
      const session = await getSession()
      const slackResponse = await connectSlack({
        authToken: session?.accessToken!,
        locale: locale!,
        data: {
          slackUserId: slackUserId as string,
          slackTeamId: slackTeamId as string
        }
      })

      if (slackResponse.success) {
        try {
          const { data } = await axios.post<{ ok: boolean }>(
            `${process.env.NEXT_PUBLIC_SLACK_APP_API}/user-loggedin/${slackUserId}`
          )

          if (data.ok) push('/slack-connected')
        } catch (error) {
          console.error(error)
        }
      } else {
        // Error flow
        console.error(slackResponse.errors)
      }

      return
    } else if (callbackUrl) {
      redirectUrl = callbackUrl as string
    }

    window.location.href = redirectUrl
  }, [isAuthenticated, query, locale, pathname, push])

  useEffect(() => {
    checker()
  }, [checker])

  return null
}

export default RedirectOnSuccess
