import { Checked, Info, Time } from 'meditopia-ui/icons'
import React, { FC } from 'react'
import Box from '../Box'
import Text from '../Text'
import { ToastIcon, ToastStyled } from './styled'
import { useToast } from './ToastContext'
import { IToastWithId } from './types'

interface Props extends IToastWithId {}

const Toast: FC<Props> = ({ title, message, status, active, id }) => {
  const { removeToast } = useToast()

  const remove = () => {
    removeToast(id)
  }

  return (
    <ToastStyled status={status} active={active} onClick={remove}>
      <ToastIcon>{icon(status)}</ToastIcon>
      <Box>
        <Text variant="heading-small" color="white.100" mt="2px">
          {title}
        </Text>

        {Boolean(message) && (
          <Text mt="1" color="white.80">
            {message}
          </Text>
        )}
      </Box>
    </ToastStyled>
  )
}

export default Toast

const icon = (status: IToastWithId['status']) => {
  if (status === 'success') return <Checked />
  if (status === 'info') return <Info />
  return <Time />
}
