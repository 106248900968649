import styled from 'styled-components'
import {
  space,
  position,
  border,
  width,
  height,
  maxWidth,
  maxHeight,
  display,
  minWidth,
  minHeight
} from 'styled-system'
import { styledColor } from 'meditopia-ui/utils'

export const ButtonBaseOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: 0.2s ease-in-out;
  background-color: ${styledColor('black.0')};
`

export const ButtonContentStyled = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonBaseStyled = styled.button`
  position: relative;
  outline: none;
  border: none;
  overflow: hidden;

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      ${ButtonBaseOverlay} {
        background-color: ${styledColor('black.16', 'black.08')};
      }
    }

    &:active {
      ${ButtonBaseOverlay} {
        background-color: ${styledColor('black.32', 'black.16')};
      }
    }
  }

  ${width}
  ${height}
  ${maxWidth}
  ${maxHeight}
  ${minWidth}
  ${minHeight}
  ${space}
  ${position}
  ${display}
  ${border}
`

ButtonBaseStyled.defaultProps = {
  type: 'button'
}
