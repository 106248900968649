import { createContext, useContext } from "react"

import { ThemeMode } from "meditopia-ui/theme"

export interface IThemeContext {
  mode: ThemeMode
  setMode: (mode: ThemeMode) => void
}

export const ThemeContext = createContext<IThemeContext>({} as IThemeContext)
export const useThemeContext = () => useContext(ThemeContext)
