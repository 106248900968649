import { CSSObject } from 'styled-components'
import { variant } from 'styled-system'
import { fontVariants } from '../Text/variants'
import { ButtonSize } from './types'

export const buttonSizes = variant<CSSObject, ButtonSize, 'size'>({
  prop: 'size',
  variants: {
    tiny: {
      padding: '0.5rem 1rem',
      ...fontVariants['body-small-medium']
    },
    small: {
      padding: '0.5rem 1.5rem',
      ...fontVariants['body-medium']
    },
    normal: {
      padding: '0.75rem 1.5rem',
      ...fontVariants['body-medium']
    },
    large: {
      padding: '1rem 2rem',
      ...fontVariants['body-medium']
    }
  }
})

export const iconSpaceValues = variant<CSSObject, ButtonSize, 'size'>({
  prop: 'size',
  variants: {
    tiny: {
      '--space-value': '0.75rem'
    },
    small: {
      '--space-value': '0.75rem'
    },
    normal: {
      '--space-value': '0.875rem'
    },
    large: {
      '--space-value': '0.875rem'
    }
  }
})

export const iconFontSize = variant<CSSObject, ButtonSize, 'size'>({
  prop: 'size',
  variants: {
    tiny: {
      fontSize: '1rem'
    },
    small: {
      fontSize: '1.25rem'
    },
    normal: {
      fontSize: '1.5rem'
    },
    large: {
      fontSize: '2rem'
    }
  }
})
