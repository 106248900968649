import * as React from "react";
import { SVGProps, memo } from "react";

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22ZM5.276 5.276a9.509 9.509 0 1 1 13.448 13.448A9.509 9.509 0 0 1 5.276 5.276Zm5.778 1.376a1.087 1.087 0 1 1 1.208 1.808 1.087 1.087 0 0 1-1.208-1.808Zm.759 1.278a.403.403 0 1 0-.309-.745.403.403 0 0 0 .309.745Zm-1.926 2.703c0-.412.334-.746.746-.746h.683a1.43 1.43 0 0 1 1.43 1.43v3.418a.621.621 0 0 0 .621.62h.684a.746.746 0 1 1 0 1.492h-.684a2.113 2.113 0 0 1-2.113-2.113V11.38h-.621a.746.746 0 0 1-.746-.746Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgInfo);
export default Memo;
