import styled, { css } from 'styled-components'
import { ifLight, styledColor } from 'meditopia-ui/utils'
import Box from '../Box'

export const ModalContainerStyled = styled(Box)`
  position: relative;
  margin: 0 auto;

  ${ifLight(css`
    border: 1px solid ${styledColor('gray.0')};
    box-shadow: 0px 4px 8px ${styledColor('black.08')};
  `)}
`

ModalContainerStyled.defaultProps = {
  px: ['4', '8', '16'],
  py: ['6', '8', '16'],
  borderRadius: 'xl',
  backgroundColor: 'gray.900',
  width: 'calc(100% - 1.5rem)',
  maxWidth: '60rem',
  _light: {
    borderRadius: ['xl', '2xl', '3xl'],
    backgroundColor: 'white.100'
  }
}

export const ModalCloseStyled = styled(Box)`
  cursor: pointer;
`

ModalCloseStyled.defaultProps = {
  zIndex: 2,
  position: 'absolute',
  right: ['5', '9'],
  top: ['7', '9'],
  color: 'white.64',
  _light: {
    color: 'gray.400'
  }
}
