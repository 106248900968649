import React from 'react'
import { Hidden } from 'react-grid-system'
import { v4 } from 'uuid'
import { ArrowDown } from 'meditopia-ui/icons'
import Box from '../Box'
import Button from '../Button'
import { useCalendar } from './CalendarContext'
import { SelectIconStyled, SelectStyled, SelectWrapperStyled } from './styled'
import { useTranslation } from 'next-i18next'

const CURRENT_YEAR = new Date().getFullYear()
const YEARS = Array.from({ length: 100 }).map(
  (_, index) => CURRENT_YEAR + 1 - index
)

const CalendarFilters = () => {
  const { t } = useTranslation(['stats', 'common'])
  const {
    currentMonth,
    currentYear,
    setCurrentMonth,
    setCurrentYear,
    setHistoryModal,
    hideHistory
  } = useCalendar()

  return (
    <Box
      display="flex"
      flexDirection="row"
      px={['6', '8']}
      pt={['6', '4']}
      flex={1}>
      <Box display="flex" flex={1} flexDirection="row">
        <Box flex={[1, 'none']} mr="4">
          <SelectWrapperStyled>
            <SelectStyled
              value={currentMonth}
              onChange={(e) =>
                setCurrentMonth(parseInt(e.currentTarget.value))
              }>
              <option value="0">{t('common:months.0')}</option>
              <option value="1">{t('common:months.1')}</option>
              <option value="2">{t('common:months.2')}</option>
              <option value="3">{t('common:months.3')}</option>
              <option value="4">{t('common:months.4')}</option>
              <option value="5">{t('common:months.5')}</option>
              <option value="6">{t('common:months.6')}</option>
              <option value="7">{t('common:months.7')}</option>
              <option value="8">{t('common:months.8')}</option>
              <option value="9">{t('common:months.9')}</option>
              <option value="10">{t('common:months.10')}</option>
              <option value="11">{t('common:months.11')}</option>
            </SelectStyled>
            <SelectIconStyled>
              <ArrowDown />
            </SelectIconStyled>
          </SelectWrapperStyled>
        </Box>

        <Box>
          <SelectWrapperStyled>
            <SelectStyled
              value={currentYear}
              onChange={(e) => setCurrentYear(parseInt(e.currentTarget.value))}>
              {YEARS.map((year) => (
                <option key={v4()} value={year.toString()}>
                  {year}
                </option>
              ))}
            </SelectStyled>
            <SelectIconStyled>
              <ArrowDown />
            </SelectIconStyled>
          </SelectWrapperStyled>
        </Box>
      </Box>

      {!hideHistory && (
        <Hidden xs sm>
          <Box display="flex" flexDirection="row">
            <Button
              colorScheme="secondary"
              size="tiny"
              onClick={() => setHistoryModal(true)}>
              {t('stats:viewMyHistory')}
            </Button>
          </Box>
        </Hidden>
      )}
    </Box>
  )
}

export default CalendarFilters
