import styled from 'styled-components'
import { switchProp } from 'styled-tools'
import { OverlayProps } from './types'

export const OverlayStyled = styled.div<OverlayProps>`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: ${switchProp('overlay', {
    gradient:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 50%)',
    'tall-gradient':
      'linear-gradient(180deg, rgba(0, 0, 0, 8e-05) 40.33%, rgba(0, 0, 0, 0.8) 74.03%)',
    transparent: 'rgba(0, 0, 0, 0.48)'
  })};
`
