import { componentFactory } from 'meditopia-ui/utils'
import styled from 'styled-components'
import Box from '../Box'

const ContainerStyled = styled(Box)``

ContainerStyled.defaultProps = {
  width: '100%',
  maxWidth: '1200px',
  px: '5',
  mx: 'auto'
}

export const ContainerThemed = componentFactory(ContainerStyled)
