const objectGet = (
  obj: Record<any, any>,
  path: string,
  defaultValue?: any
): any => {
  if (!obj || !path) throw new Error('Object and path are required.')

  let result = { ...obj }
  const pathBreakPoints = path.split('.')

  for (let index = 0; index < pathBreakPoints.length; index++) {
    const breakPoint = pathBreakPoints[index]
    if (result.hasOwnProperty(breakPoint)) {
      result = result[breakPoint]
    }
  }

  return result || defaultValue
}

export default objectGet
