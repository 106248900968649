import { Box, Button } from 'meditopia-ui/components'
import { signOut } from 'next-auth/react'

const DevSignoutButton = () => {
  const handleSignout = async () => {
    await signOut({ redirect: false })
    console.log('signout')
  }

  return (
    <Box
      zIndex={1000}
      position="fixed"
      left="1rem"
      bottom={{ _: '7rem' }}>
      <Button onClick={handleSignout} colorScheme="secondary">Sign Out</Button>
    </Box>
  )
}

export default DevSignoutButton
