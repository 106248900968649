import "@fontsource/eb-garamond/500.css"

import React, { Fragment, useEffect, useState } from "react"
import Head from "next/head"
import Script from "next/script"
import { SessionProvider, useSession } from "next-auth/react"
import { appWithTranslation } from "next-i18next"
import { ThemeProvider } from "styled-components"

import { Layout } from "components/Layout"
import { AppProvider } from "context/app"
import { GTM_ID } from "lib/gtm"
import { GlobalStyles } from "meditopia-ui/styles/globalStyles"
import theme from "meditopia-ui/theme"

import type { CustomAppProps } from "../types/nextCustomType"

import "styles/normalize.css"
import "styles/fonts.css"

import Router from "next/router"
import { DevThemeToggler } from "components"

import DevSignoutButton from "components/DevSignoutButton"
import PageLoading from "components/PageLoading"
import RedirectOnSuccess from "components/RedirectOnSuccess"
import { ThemeContext } from "context/theme-context"
import { ToastProvider } from "meditopia-ui/components"
import usePreferredThemeMode from "meditopia-ui/hooks/usePreferredThemeMode"

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  const { themeMode, changeThemeMode } = usePreferredThemeMode()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }
    const end = () => {
      setLoading(false)
    }
    Router.events.on("routeChangeStart", start)
    Router.events.on("routeChangeComplete", end)
    Router.events.on("routeChangeError", end)
    return () => {
      Router.events.off("routeChangeStart", start)
      Router.events.off("routeChangeComplete", end)
      Router.events.off("routeChangeError", end)
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{ mode: themeMode, setMode: changeThemeMode }}
    >
      <ThemeProvider theme={{ ...theme, mode: themeMode }}>
        <GlobalStyles />
        <AppProvider>
          <SessionProvider
            session={session}
            refetchInterval={5 * 60 * 60}
            refetchOnWindowFocus={false}
          >
            <Head>
              <title>Meditopia</title>
              <meta name="description" content="Generated by create next app" />
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <link rel="icon" href="/favicon.ico" />
            </Head>

            <Script
              id="gtmScript"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${GTM_ID}');
                  `,
              }}
            />

            {process.env.NODE_ENV === "development" && <DevThemeToggler />}

            {process.env.NODE_ENV === "development" && <DevSignoutButton />}

            <RedirectOnSuccess />

            <Layout>
              {loading && <PageLoading />}
              <ToastProvider>
                {Component.auth ? (
                  <Auth>
                    <Component {...pageProps} />
                  </Auth>
                ) : (
                  <Component {...pageProps} />
                )}
              </ToastProvider>
            </Layout>
          </SessionProvider>
        </AppProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

type AuthPropsType = {
  children?: React.ReactNode
}

const Auth: React.FC<AuthPropsType> = ({ children }) => {
  const { data: session } = useSession({
    required: true,
  })
  const isUser = !!session?.user

  if (isUser) {
    return <Fragment>{children}</Fragment>
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <div />
}

export default appWithTranslation(MyApp)
