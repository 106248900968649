import type { State, Action } from 'types/context'

const setAppState = (currentState: State) => {
  localStorage.setItem('state', JSON.stringify(currentState))
  return currentState
}

export enum ActionType {
  Loading = 'LOADING',
  Loading2 = 'LOADING2'
}

export const setLoading = (payload: boolean) => ({
  type: ActionType.Loading,
  payload
})

export const setLoading2 = (payload: boolean) => ({
  type: ActionType.Loading2,
  payload
})

const reducer = (state: State, action: Action) => {
  const { type, payload } = action

  switch (type) {
    case ActionType.Loading:
      return setAppState({
        ...state,
        loading: payload
      })

    case ActionType.Loading2:
      return setAppState({
        ...state,
        loading2: payload
      })
    default:
      return state
  }
}

export default reducer
