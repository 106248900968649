import styled, { css } from 'styled-components'
import { AspectRatioProps } from './types'

export const AspectRatioStyled = styled.div<AspectRatioProps>`
  position: relative;
  width: 100%;
  ${({ width, height, minHeight }) =>
    css`
      padding-top: ${(100 * height) / width}%;
      min-height: ${minHeight || 0}px;
    `}
`

export const AspectRatioContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
