import { styledColor } from 'meditopia-ui/utils'
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: #2C74F4;
  stroke-width: 2px;
  transition: 0.2s ease-in;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 2px solid ${styledColor('white.16', 'gray.100')};
  transition: 0.2s ease-in;

  ${HiddenCheckbox}:focus + & {
    outline: 3px solid ${styledColor('secondary.main')}56;
  }

  ${Icon} {
    visibility: ${props => (props?.checked ? 'visible' : 'hidden')};
  }
`

export const CheckboxLabel = styled.span`
  margin-left: 8px;
  color: #696C74;
`
