import React, { forwardRef, useState } from 'react'
import Box from '../Box'
import Text from '../Text'
import {
  ErrorContainerStyled,
  InputStyled,
  InputWrapperStyled,
  PlaceholderStyled
} from './styled'
import type { InputProps } from './types'

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, onFocus, onBlur, value, error = '', ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false)
    const isPlaceholderUp = Boolean(value) || isFocused

    const handleFocus: typeof onFocus = (event) => {
      onFocus?.(event)
      setIsFocused(true)
    }

    const handleBlur: typeof onBlur = (event) => {
      onBlur?.(event)
      setIsFocused(false)
    }

    return (
      <Box>
        <InputWrapperStyled isFocused={isFocused} error={Boolean(error)}>
          <PlaceholderStyled isUp={isPlaceholderUp}>
            {placeholder}
          </PlaceholderStyled>
          <InputStyled
            value={value}
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={ref}
          />
        </InputWrapperStyled>

        <ErrorContainerStyled isActive={Boolean(error)}>
          <Text color="warning">{error}</Text>
        </ErrorContainerStyled>
      </Box>
    )
  }
)

Input.displayName = 'Input'

export default Input
