import React, { FC } from 'react'
import {
  ButtonBaseOverlay,
  ButtonBaseStyled,
  ButtonContentStyled
} from './styled'
import { ButtonBaseProps } from './types'

const ButtonBase: FC<ButtonBaseProps> = ({ children, ...props }) => {
  return (
    <ButtonBaseStyled {...props}>
      <ButtonBaseOverlay />
      <ButtonContentStyled>{children}</ButtonContentStyled>
    </ButtonBaseStyled>
  )
}

export default ButtonBase
