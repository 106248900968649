import { styledColor } from 'meditopia-ui/utils'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import Box from '../Box'

export const AlertStyled = styled(Box)`
  border-radius: 1rem;
  padding: 1rem;
  border: 1px solid ${styledColor('warning')};
  background-color: ${styledColor('warning')}20;
  color: ${styledColor('warning')};
  margin-bottom: 0.5rem;
  min-height: 3.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const AlertContainerStyled = styled(Box)<{ isActive: boolean }>`
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  opacity: 0;

  ${ifProp(
    'isActive',
    css`
      margin-bottom: ${theme('space.6')};
      max-height: 5rem;
      opacity: 1;
    `
  )}
`
