import { styledColor } from 'meditopia-ui/utils'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

export const MonthContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const MonthDateStyled = styled.button<{
  isSelected: boolean
  isHighlighted: boolean
}>`
  width: calc(100% / 7);
  padding: 1rem 0.25rem;
  border-radius: 99rem;
  background: transparent;
  outline: none;
  border: none;
  transition: 0.2s ease-in-out;

  &:disabled {
    color: ${styledColor('white.40', 'gray.300')};
  }

  &:not(:disabled) {
    cursor: pointer;

    ${ifProp(
      'isSelected',
      css`
        color: ${styledColor('secondary.main')};
        background-color: ${styledColor('black.24', 'white.100')};
      `,
      css`
        color: ${styledColor('white.100', 'gray.600')};
        &:hover {
          background-color: ${styledColor('black.08', 'white.48')};
        }
      `
    )}

    ${ifProp(
      'isHighlighted',
      css`
        background-color: ${styledColor('secondary.main', 'primary.main')};
        color: ${styledColor('white.100')};
      `
    )}
  }
`

export const SelectWrapperStyled = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${styledColor('black.32', 'white.100')};
  color: ${styledColor('white.100', 'gray.600')};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
`

export const SelectStyled = styled.select`
  background-color: transparent;
  color: ${styledColor('white.100', 'gray.600')};
  padding: 4px 12px;
  padding-right: 40px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`

export const SelectIconStyled = styled.div`
  position: absolute;
  right: 12px;
  line-height: 0;
  pointer-events: none;
`
