import { Theme } from 'meditopia-ui/theme'
import type { SingleColorOptions } from 'meditopia-ui/theme/colors'
import objectGet from './objectGet'

const getThemeColor = (
  theme: Theme,
  darkKey: SingleColorOptions,
  lightKey?: SingleColorOptions
) => {
  const { mode, colors } = theme

  let colorKey = darkKey

  if (mode === 'light' && lightKey) colorKey = lightKey

  return objectGet(colors, colorKey)
}

export default getThemeColor
