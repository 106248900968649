const breakpoints: string[] & {
  sm: string
  md: string
  lg: string
  xl: string
  '2xl': string
} = Object.assign(['576px', '768px', '992px', '1200px', '1600px'], {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1600px'
})

export type BreakpointOptions = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export default breakpoints
