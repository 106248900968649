import * as React from "react";
import { SVGProps, memo } from "react";
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.93 10.67a.76.76 0 0 0-.75-.67H12.75a.76.76 0 0 0-.75.75v3.5a.76.76 0 0 0 .75.75h5.58A7 7 0 1 1 12 5a7 7 0 0 1 4.23 1.42.74.74 0 0 0 1-.07l2.47-2.49a.75.75 0 0 0 .22-.57.77.77 0 0 0-.27-.54A12 12 0 1 0 24 12a11.85 11.85 0 0 0-.07-1.33Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGoogle);
export default Memo;
