import { componentFactory } from 'meditopia-ui/utils'
import styled, { CSSObject } from 'styled-components'
import { border, color, system, variant } from 'styled-system'
import { ifProp } from 'styled-tools'
import Box from '../Box'
import ButtonBase from '../ButtonBase'
import {
  ButtonColorScheme,
  ButtonProps,
  ButtonVariant,
  IconContainerProps
} from './types'
import { buttonSizes, iconFontSize, iconSpaceValues } from './variants'

export const IconContainerStyled = styled(Box)<IconContainerProps>`
  line-height: 0;
  ${iconSpaceValues}
  ${iconFontSize}
  ${ifProp(
    { placement: 'left' },
    'margin-right: var(--space-value);',
    'margin-left: var(--space-value);'
  )}
`

export const ButtonStyled = styled(ButtonBase)<ButtonProps>`
  border-radius: 99rem;
  ${buttonSizes}

  ${({ theme: { colors } }) =>
    variant<CSSObject, ButtonColorScheme, 'colorScheme'>({
      prop: 'colorScheme',
      variants: {
        primary: {
          '--color-scheme': colors.primary.main
        },
        secondary: {
          '--color-scheme': colors.secondary.main
        }
      }
    })}

    ${({ theme: { colors } }) =>
    variant<CSSObject, ButtonVariant, 'variant'>({
      prop: 'variant',
      variants: {
        contained: {
          backgroundColor: 'var(--color-scheme)',
          color: colors.white[100]
        },
        helper: {
          backgroundColor: colors.white[100],
          color: 'var(--color-scheme)'
        },
        ghost: {
          backgroundColor: 'transparent',
          color: 'var(--color-scheme)'
        }
      }
    })}
    
    ${color}
    ${border}
    ${system({
      width: true
    })}
    ${system({
      height: true
    })}
`

export const ButtonThemed = componentFactory(ButtonStyled)

export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 1em;
  height: 1em;

  :after {
    content: ' ';
    display: block;
    width: 0.8em;
    height: 0.8em;
    margin: 0.1em;
    border-radius: 50%;
    border: 0.075em solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: dual-ring 1.2s linear infinite;
  }

  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
