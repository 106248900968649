import { styledColor } from 'meditopia-ui/utils'
import styled from 'styled-components'
import { theme } from 'styled-tools'

export const ControlButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${styledColor('gray.600')};

  * {
    position: absolute;
  }

  svg {
    font-size: 2.5rem;
  }

  @media (max-width: ${theme('breakpoints.md')}) {
    color: ${styledColor('gray.100')};
  }
`

export const PlayerRangeBackground = styled.div`
  background: ${styledColor('white.16', 'black.24')};
  box-shadow: 0 0.75rem 1rem ${styledColor('black.16', 'black.24')};
  backdrop-filter: blur(0.5rem);
  border-radius: 100rem;
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 4px;
`

export const PlayerRangeCurrent = styled.div`
  background: ${styledColor('white.100', 'gray.700')};
  height: 4px;
  position: absolute;
  z-index: 2;
  border-radius: 100rem;
  left: 0;
`

export const PlayerRangeStyled = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 1rem;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    border: none;
  }

  &::-webkit-slider-runnable-track {
    background: transparent;
  }

  &::-webkit-slider-thumb {
    background-color: ${styledColor('white.100')};
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    border-radius: 99rem;
    opacity: 0;
  }

  &::-moz-range-track {
    background: transparent;
  }

  &::-moz-range-thumb {
    background-color: ${styledColor('white.100')};
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    border-radius: 99rem;
    opacity: 0;
  }
`
