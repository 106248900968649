import { componentFactory } from 'meditopia-ui/utils'
import React from 'react'
import Box from '../Box'
import { DividerProps } from './types'

const DividerBase: React.FC<DividerProps> = ({ children, color }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flex="1" height="1px" bg={color} />
      <Box px="4">{children}</Box>
      <Box flex="1" height="1px" bg={color} />
    </Box>
  )
}

export default componentFactory(DividerBase)
