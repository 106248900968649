import { styledColor } from 'meditopia-ui/utils'
import styled from 'styled-components'
import Box from '../Box'

export const HistoryItemActivityWrapper = styled.div``

export const HistorySectionPoint = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -2rem;
  top: 0;
  transform: translateX(-100%);
  background-color: ${styledColor('white.16', 'black.08')};

  &::before {
    content: '';
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 999rem;
    background-color: ${styledColor('white.100')};
  }
`

export const HistroySectionWrapper = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 2rem;
    position: relative;
  }
`

export const HistoryItemActivityPointDot = styled(Box)`
  outline: 4px solid ${styledColor('gray.900', 'white.100')};
`
