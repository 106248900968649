import React from "react"
import { useRouter } from "next/router"

import { ThemeMode } from "meditopia-ui/theme"

export default function usePreferredThemeMode() {
  const router = useRouter()
  const [themeMode, setThemeMode] = React.useState<ThemeMode>("dark")

  React.useEffect(() => {
    if (["light", "dark"].includes(router.query?.forcedTheme as string)) {
      setThemeMode(router.query?.forcedTheme as ThemeMode)
      return
    }

    const mode = getLocalStorageThemeMode()
    if (mode) {
      setThemeMode(mode)
      return
    }

    const isSystemDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches

    if (!isSystemDark) setThemeMode("light")
  }, [router.query?.forcedTheme])

  const changeThemeMode = React.useCallback((mode: ThemeMode) => {
    setThemeMode(mode)
    window.localStorage.setItem("THEME_MODE", mode)
  }, [])

  return { themeMode, changeThemeMode }
}

function getLocalStorageThemeMode() {
  const mode = window.localStorage.getItem("THEME_MODE")
  return mode as ThemeMode | null
}
