import styled from 'styled-components'
import { flexbox, typography, color } from 'styled-system'
import { componentFactory } from 'meditopia-ui/utils'
import { backdropFilterSystem } from 'meditopia-ui/systems'
import ButtonBase from '../ButtonBase'
import { IconButtonProps } from './types'

export const IconButtonStyled = styled(ButtonBase)<IconButtonProps>`
  ${color}
  ${flexbox}
  ${typography}
  ${backdropFilterSystem}
`

IconButtonStyled.defaultProps = {
  width: '2rem',
  height: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 0,
  fontSize: '1rem',
  borderRadius: '99rem'
}

export const IconButtonThemed = componentFactory(IconButtonStyled)

IconButtonThemed.defaultProps = {
  width: '2rem',
  height: '2rem',
  fontSize: '1rem',
  backgroundColor: 'white.08',
  color: 'white.100',
  _light: {
    backgroundColor: 'gray.0',
    color: 'gray.800'
  }
}
