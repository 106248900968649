import { createGlobalStyle } from 'styled-components'
import { styledColor } from 'meditopia-ui/utils'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: ${styledColor('black.100', 'white.100')};
    color: ${styledColor('white.100', 'gray.600')};
    height: 100%;
  }
  
  #__next {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .portal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    pointer-events: none;

    & > div {
      width: 100%;
      height: 100%;
    }
  }
`
