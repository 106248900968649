import styled from 'styled-components'
import { componentFactory } from 'meditopia-ui/utils'
import { typography, variant } from 'styled-system'
import type { TextProps } from './types'
import { fontVariants } from './variants'
import Box from '../Box'

export const TextBase = styled(Box)<TextProps>`
  font-family: Hanken Sans, sans-serif;

  ${variant({
    variants: fontVariants
  })}
  ${typography}
`

TextBase.defaultProps = {
  variant: 'body-regular'
}

export const Text = componentFactory(TextBase)
