import styled from 'styled-components'
import { styledColor } from '../../meditopia-ui/utils'

export const PageLoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${styledColor('black.64', 'white.64')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

export const Spinner = styled.div`
  border: 6px solid ${styledColor('primary.light.100')};
  border-top: 6px ${styledColor('primary.dark.100')} solid;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
