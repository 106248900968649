import { Theme } from 'meditopia-ui/theme'

export function ifLight(returnStatement: any) {
  return ({ theme: { mode } }: { theme: Theme }) => {
    if (mode === 'light') return returnStatement
    return ''
  }
}

export function ifDark(returnStatement: any) {
  return ({ theme: { mode } }: { theme: Theme }) => {
    if (mode === 'dark') return returnStatement
    return ''
  }
}
